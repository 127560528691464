import CoreLayout from "@/layouts/Layout.vue";
import Dashboard from "@/views/dashboard/preview.vue";

let dashboardRoute = {
  path: "/",
  component: CoreLayout,
  name: "Начало",
  redirect: "/dashboard",
  hidden: true,
  meta: {
    actions: ["Потребител", "Оператор", "Администратор"],
    groups: ["TestMainGroup", "TestDummyGroup","valves"],
  },
  children: [
    {
      path: "/dashboard",
      name: "Начало",
      component: Dashboard,
      meta: {
        title: "Начало",
        actions: ["Потребител", "Оператор", "Администратор"],
        groups: ["TestMainGroup", "TestDummyGroup","valves"],
        icon: "",
        goBackTitle: "Обратно към начало",
      },
      hidden: true
    }
  ]
}

export default dashboardRoute;
