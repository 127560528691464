 let valves = {
   name: "Управление на мрежата",
   //path: "https://valves.vik-vratza.eu:9443/valves",
   //path: "https://valves.vik-ruse.com/valves",
  // path: "https://valves.aquagis.eu/valves",
   //path:"https://valeves.vik-pernik.eu/valves",
   path: process.env.VUE_APP_VALVES_URL,
   target: true,
   meta:{ icon_fa: "fas fa-network-wired"},
   groups: ["TestMainGroup", "TestDummyGroup","valves"],
 }

//let valves = {
//  name: "Управление на мрежата",
//  path: "https://valves.vik-pernik.eu/valves",
//  target: true,
//  meta: { icon_fa: "fa-solid fa-pipe-valve" },
//  groups: ["TestMainGroup", "TestDummyGroup"],
//}

export default valves;
